import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArcherContainer } from "react-archer";

import TalentLiftLogo from "../images/talentlift-canada-light-bg.png";
import StepExplanation from "./StepExplanation";
import StageHeader from "./StageHeader";
import VerticalLine from "./VerticleLine";
import Point from "./Point";
import LanguageSelector from "./LanguageSelector";

import step1Image from "../images/step-1.png";
import step2Image from "../images/step-2.png";
import step3Image from "../images/step-3.png";
import step4Image from "../images/step-4.png";
import step5Image from "../images/step-5.png";
import step6Image from "../images/step-6.png";
import step7Image from "../images/step-7.png";
import step8Image from "../images/step-8.png";
import step9Image from "../images/step-9.png";

const renderVerticalLines = (count) => {
  const lines = [];
  for (let i = 0; i < count; i++) {
    lines.push(<VerticalLine key={i} className="hidden md:block" />);
  }
  return lines;
};

const changeLanguageDirection = () => {
  if (localStorage.getItem("i18nextLng") === "ar") {
    return { direction: "rtl" };
  }
  return {};
};

const isSpanish = () => localStorage.getItem("i18nextLng") === "es";
const isFrench = () => localStorage.getItem("i18nextLng") === "fr";

const TalentLiftJobSeekerJourneyMap = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  let { language } = useParams();
  // set default language to English
  if (!language) language = "en";
  const queryParameters = new URLSearchParams(window.location.search);
  const status = queryParameters.get("status");
  let completed = queryParameters.get("completed");
  if (completed) completed = completed.split(",");

  useEffect(() => {
    const sectionToScroll = document.getElementById(status);
    if (sectionToScroll) {
      sectionToScroll.scrollIntoView({ behavior: "smooth" });
    }
    i18n.changeLanguage(language);
  }, []);

  const highlightOrCompleted = (id) => ({
    highlight: status === id,
    completed: completed?.includes(id),
  });

  return (
    <div>
      <ArcherContainer
        strokeColor="#54c8f3"
        svgContainerStyle={{
          zIndex: 1,
          display: window.innerWidth < 768 ? "none" : "block",
        }}
      >
        <div className="relative">
          <LanguageSelector />
          <a href="https://www.talentlift.ca/" target="_blank" rel="noreferrer">
            <img
              src={TalentLiftLogo}
              alt="TalentLift Logo"
              className="block w-24 h-auto float-right p-4"
            />
          </a>

          <h1 className="text-5xl md:text-6xl md:leading-[20px] mb-4 mt-28 clear-both">
            <p className="text-darkBlue" style={changeLanguageDirection()}>
              {t("TalentLift")}
            </p>{" "}
            <p
              className="text-lightBlue italic"
              style={changeLanguageDirection()}
            >
              {t("Job Seeker")}
            </p>
            <p className="text-darkBlue" style={changeLanguageDirection()}>
              {t("Journey Map")}
            </p>
          </h1>
          <p
            className="w-[80%] xl:w-[65%] 2xl:w-[45%] mx-auto font-medium text-xl"
            style={changeLanguageDirection()}
          >
            {t(
              "You're seeking an international job and skilled visa to Canada with TalentLift. What's next?"
            )}
          </p>
          <p
            className="w-[80%] xl:w-[70%] 2xl:w-[50%] mx-auto font-medium text-xl"
            style={changeLanguageDirection()}
          >
            {t(
              "Everyone's journey as a TalentLift Candidate will be unique. Some Candidates will get a job with one of our Canadian employer partners. Others will beneﬁt from the resources on our platform during their own job search. International recruitment can be competitive and unpredictable. We're here to support you the best we can. This journey map will give you an idea of what you might experience as a TalentLift Candidate and how to put your best foot forward to employers."
            )}
          </p>
          <StageHeader
            heading={`${t("First, let's get you set up")}`}
            style={changeLanguageDirection()}
          />
          {renderVerticalLines(7)}
          <Point
            percentage="65%"
            right
            {...highlightOrCompleted("registering")}
          />
          <StepExplanation
            id="registering"
            image={step1Image}
            imageStyle="md:top-[54%] md:left-[77%] md:w-[9rem] lg:w-[10rem] xl:left-[75%] 2xl:top-[52%] 2xl:left-[70%] 2xl:w-[12rem]"
            className="top-[65%]"
            header={`${t("Register with TalentLift")}`}
            explanation={`${t(
              "We invite anyone living in refugee circumstances outside their country of origin who is interested in international employment and skilled immigration to Canada, to join TalentLift by registering a proﬁle on our platform. Welcome to TalentLift!"
            )}`}
            position="right"
            {...highlightOrCompleted("registering")}
          />
          <Point
            percentage="80%"
            left
            {...highlightOrCompleted("profile-completion")}
          />
          <StepExplanation
            id="profile-completion"
            image={step2Image}
            imageStyle="md:top-[72%] md:left-[5%] md:w-[9.5rem] lg:w-[10.5rem] xl:left-[11%] 2xl:top-[70%] 2xl:left-[17%] 2xl:w-[12.5rem]"
            className="top-[80%]"
            header={`${t("Complete your proﬁle & CV")}`}
            explanation={`${t(
              "Take the time to complete your proﬁle by sharing details about your work experience, education, and language ability. Make sure you upload your CV or create one on your proﬁle. This information helps us to better match you with job opportunities. We encourage you to keep your proﬁle updated and as truthful as possible for the best chance of success."
            )}`}
            position="left"
            {...highlightOrCompleted("profile-completion")}
          />
        </div>

        <div className="bg-white relative pb-8">
          <StageHeader
            heading={`${t(
              "Next... We can't guarantee how long your job search will take. Landing a job can take months and even years."
            )}`}
            style={changeLanguageDirection()}
          />
          {renderVerticalLines(16)}

          <Point
            percentage="22%"
            right
            bgColor="white"
            id="explore-jobs"
            {...highlightOrCompleted("explore-jobs")}
          />
          <StepExplanation
            id="explore-jobs"
            bgColor="white"
            image={step3Image}
            imageStyle={`${
              isSpanish() ? "md:top-[15.5%]" : "md:top-[17%]"
            } md:left-[79%] md:w-[9rem] lg:w-[10rem] xl:top-[19%] 2xl:top-[16%] 2xl:left-[75%] 2xl:w-[11rem]`}
            className="top-[22%]"
            header={`${t("Explore Jobs")}`}
            explanation={`${t(
              "TalentLift will publish job opportunities from our employer partners on your TalentLift proﬁle. Watch for opportunities you think are a good ﬁt for you. You can express your interest in any opportunity and TalentLift will review your candidacy. We'll be in touch if your work experience is a good ﬁt for the opportunity and the available visa pathways."
            )}`}
            position="right"
            {...highlightOrCompleted("explore-jobs")}
          />

          <Point
            percentage="37%"
            left
            bgColor="white"
            {...highlightOrCompleted("improve-english")}
          />
          <StepExplanation
            id="improve-english"
            bgColor="white"
            image={step4Image}
            imageStyle="w-[13.5rem] md:top-[28%] md:left-[-5%] md:w-[11rem] lg:left-[1%] lg:w-[12rem] xl:top-[29%] xl:left-[7%] xl:w-[13rem] 2xl:left-[13%] 2xl:w-[14rem]"
            className="top-[37%]"
            header={`${t("Improve your English")}`}
            explanation={`${t(
              "Most employers in Canada need their employees to speak some English. TalentLift offers English language training free of charge to help you continuously improve. You can express interest in this training on your proﬁle and TalentLift will refer you to a free training program based on your current English level."
            )}`}
            position="left"
            {...highlightOrCompleted("improve-english")}
          />

          <Point
            percentage="57%"
            right
            bgColor="white"
            {...highlightOrCompleted("job-search")}
          />
          <StepExplanation
            id="job-search"
            bgColor="white"
            image={step5Image}
            imageStyle={`md:top-[47%] md:left-[79%] md:w-[9rem] lg:w-[9.5rem] xl:top-[50%] 2xl:top-[48%] 2xl:left-[75%] 2xl:w-[10.5rem] ${
              isFrench() && "xl:top-[47%]"
            }`}
            className="top-[57%]"
            header={`${t("Start your own job search")}`}
            explanation={`${t(
              "While we ﬁnd new job opportunities with our employer partners, you can start your own job search by applying to online job posts in Canada. To assist you, we created a support letter available on your proﬁle that lets employers know that TalentLift can support your relocation if you're offered a job in Canada. You may include this letter in any job applications you submit. Please be aware of scams during your job search. Read about common indicators of immigration fraud on your proﬁle."
            )}`}
            position="right"
            {...highlightOrCompleted("job-search")}
          />

          <Point
            percentage="77%"
            left
            bgColor="white"
            {...highlightOrCompleted("skill-maintenance")}
          />
          <StepExplanation
            id="skill-maintenance"
            bgColor="white"
            image={step6Image}
            imageStyle="w-[13.5rem] md:top-[67%] md:left-[-5%] md:w-[11rem] lg:left-[1%] lg:w-[12rem] xl:top-[67%] xl:left-[7%] xl:w-[13rem] 2xl:left-[13%] 2xl:w-[14rem]"
            className="top-[77%] pb-8"
            header={`${t("Keep your skills current")}`}
            explanation={`${t(
              "The international job search can be long and it can be tough waiting to hear back from employers. Use this time to pursue opportunities that keep your skills current including courses, volunteering or personal projects. And if you spot upskilling opportunities of interest that are not currently offered by TalentLift, please let us know about them."
            )}`}
            position="left"
            {...highlightOrCompleted("skill-maintenance")}
          />
        </div>

        <div className="pb-8 relative">
          <StageHeader
            heading={`${t(
              "If an employer working with TalentLift has a job opportunity that matches your skills, here's what to expect:"
            )}`}
            style={changeLanguageDirection()}
          />
          {renderVerticalLines(9)}

          <Point
            percentage="33%"
            right
            id="shortlist"
            {...highlightOrCompleted("shortlist")}
          />
          <StepExplanation
            id="shortlist"
            image={step7Image}
            imageStyle="md:top-[24%] md:left-[79%] md:w-[9rem] xl:top-[23%] xl:left-[80%] xl:w-[10.5rem] 2xl:left-[77%]"
            className="top-[33%]"
            header={`${t("Shortlist")}`}
            explanation={`${t(
              "If your skills look right for an employer working with TalentLift in Canada, we will contact you. We may not contact you until there's a job that matches your skills.The ﬁrst time we contact you, we usually ask to schedule a video call. This call helps us better understand your eligibility for a job and a skilled visa pathway. Then, we'll share your CV directly with an employer."
            )}`}
            position="right"
            {...highlightOrCompleted("shortlist")}
          />

          <Point percentage="65%" left {...highlightOrCompleted("interview")} />
          <StepExplanation
            id="interview"
            image={step8Image}
            imageStyle="w-[16rem] md:top-[49%] md:left-[-2%] md:w-[14rem] lg:left-0 lg:w-[17rem] xl:left-[5%] xl:w-[19rem] 2xl:left-[13%]"
            className="top-[65%]"
            header={`${t("Interview")}`}
            explanation={`${t(
              "If an employer is interested in your CV, they will invite you to a video interview. Our team will assist you in preparing so you can put your best foot forward. Not every interview leads to a job offer but each one is a learning opportunity."
            )}`}
            position="left"
            {...highlightOrCompleted("interview")}
          />

          <Point
            percentage="90%"
            right
            {...highlightOrCompleted("job-offer")}
          />
          <StepExplanation
            id="job-offer"
            image={step9Image}
            imageStyle={`${
              isSpanish() ? "md:top-[74%]" : "md:top-[72%]"
            } md:left-[78%] md:w-[9rem] xl:top-[75%] xl:left-[79%] xl:w-[11rem] 2xl:left-[79%]`}
            className="top-[90%] pb-8"
            header={`${t("Job Offer")}`}
            explanation={`${t(
              "If an employer offers you a job, and you accept it, our legal team will work with you and your future employer to submit a skilled visa application. We will support you and your eligible family to relocate to Canada."
            )}`}
            position="right"
            {...highlightOrCompleted("job-offer")}
          />
        </div>
      </ArcherContainer>
    </div>
  );
};

export default TalentLiftJobSeekerJourneyMap;
