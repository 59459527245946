import React from "react";
import VerticalLine from "./VerticleLine";

const StageHeader = ({ heading, style }) => {
  return (
    <div>
      {" "}
      <p className="fa-solid fa-location-dot text-lightRed text-7xl mt-8 mb-4"></p>
      <VerticalLine className="mt-0" />
      <h2
        className="text-lightRed w-[70%] xl:w-[45%] leading-8 mx-auto md:-mt-8 mb-16"
        style={style}
      >
        {heading}
      </h2>
    </div>
  );
};

export default StageHeader;
