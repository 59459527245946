import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import TalentLiftJourneyMap from "./components/TalentLiftJourneyMap";

function App() {
  const initialRefreshedState = localStorage.getItem("refreshed") === "true";
  const [refreshedOnce, setRefreshedOnce] = useState(initialRefreshedState);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        if (!refreshedOnce) {
          window.location.reload();
          setRefreshedOnce(true);

          // Store 'refreshed' state in localStorage
          localStorage.setItem("refreshed", "true");
        }
      } else {
        // If screen size is greater than 768, reset the 'refreshed' state
        setRefreshedOnce(false);

        // Remove 'refreshed' state from localStorage
        localStorage.removeItem("refreshed");
      }
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [refreshedOnce]);
  return (
    <div className="App">
      <Router>
        <Routes>
          {["/", "/:language"].map((path) => (
            <Route key="path" path={path} element={<TalentLiftJourneyMap />} />
          ))}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
