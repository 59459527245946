import React from "react";
import PulsatingCircle from "./PulsatingCircle/PulsatingCircle";
import { ArcherElement } from "react-archer";

const Point = ({
  percentage,
  right,
  left,
  bgColor,
  id,
  highlight,
  completed,
}) => {
  if (!bgColor) bgColor = "#E9E5DE";
  return (
    <ArcherElement id={id}>
      <div
        className={`hidden md:flex w-fit absolute top-[${percentage}] ${
          left ? "right-1/2" : "left-1/2"
        } mt-4`}
        style={{
          transform: right ? "translate(-1rem)" : "translate(1rem)",
          backgroundColor: bgColor,
        }}
      >
        {completed && !highlight && (
          <i
            className={`absolute fa-solid fa-check text-[1.5rem] text-darkBeige ${
              bgColor === "white" && "text-white"
            } mt-[3.5px] ${left && "ml-[39px]"} ${right && "ml-[5px]"}`}
          ></i>
        )}
        {highlight && (
          <PulsatingCircle
            bgColor={bgColor}
            top={percentage}
            style={{
              transform: right ? "translate(-1.2rem)" : "translate(1rem)",
            }}
          />
        )}
        {right && (
          <i
            className={`fa-regular ${
              completed && !highlight && "fa-solid"
            } fa-circle text-lightRed text-3xl`}
          ></i>
        )}
        <div className="mt-[13px] w-8 border-2 border-solid border-lightRed self-start"></div>
        {left && (
          <i
            className={`fa-regular  ${
              completed && !highlight && "fa-solid"
            } fa-circle text-lightRed bg-${bgColor} text-3xl`}
          ></i>
        )}
      </div>
    </ArcherElement>
  );
};

export default Point;
