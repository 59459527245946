import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const { i18n } = useTranslation();

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    navigate(`/${lng}?${queryParameters}`);
    window.location.reload();
  };

  return (
    <div className="inline-flex float-left p-4">
      <button
        className={`bg-transparent hover:bg-darkBlue text-black font-semibold hover:text-white py-2 px-4 border border-darkBlue hover:border-transparent rounded cursor-pointer mr-2 ${
          localStorage.getItem("i18nextLng") === "en" &&
          "!bg-darkBlue text-white border-transparent"
        }`}
        onClick={() => handleLanguageChange("en")}
      >
        English
      </button>{" "}
      <button
        className={`bg-transparent hover:bg-darkBlue text-black font-semibold hover:text-white py-2 px-4 border border-darkBlue hover:border-transparent rounded cursor-pointer mr-2 ${
          localStorage.getItem("i18nextLng") === "fr" &&
          "!bg-darkBlue text-white border-transparent"
        }`}
        onClick={() => handleLanguageChange("fr")}
      >
        Français
      </button>
      <button
        className={`bg-transparent hover:bg-darkBlue text-black font-semibold hover:text-white py-2 px-4 border border-darkBlue hover:border-transparent rounded cursor-pointer mr-2 ${
          localStorage.getItem("i18nextLng") === "es" &&
          "!bg-darkBlue text-white border-transparent"
        }`}
        onClick={() => handleLanguageChange("es")}
      >
        Español
      </button>
      <button
        className={`bg-transparent hover:bg-darkBlue text-black font-semibold hover:text-white py-2 px-4 border border-darkBlue hover:border-transparent rounded cursor-pointer ${
          localStorage.getItem("i18nextLng") === "ar" &&
          "!bg-darkBlue text-white border-transparent"
        }`}
        onClick={() => handleLanguageChange("ar")}
      >
        العربية
      </button>
    </div>
  );
};

export default LanguageSelector;
