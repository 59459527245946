import React from "react";

const VerticalLine = ({ className }) => {
  return (
    <div
      className={`mx-auto my-16 w-8 border-l-20 border-solid border-lightBlue ${className}`}
      style={{ transform: "rotate(90deg)" }}
    ></div>
  );
};

export default VerticalLine;
