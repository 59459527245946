import React from "react";
import "./LineDivider.css";

const LineDivider = () => {
  return (
    <div className="h-divider md:hidden">
      <div className="shadow"></div>
    </div>
  );
};

export default LineDivider;
