import React from "react";
import { useTranslation } from "react-i18next";
import { ArcherElement } from "react-archer";

import PulsatingCircle from "./PulsatingCircle/PulsatingCircle";
import LineDivider from "./LineDivider/LineDivider";

const rtlLang = localStorage.getItem("i18nextLng") === "ar";

const changeLanguageDirection = () => {
  if (rtlLang) {
    return { direction: "rtl", zIndex: 2 };
  }
  return {};
};

const isSpanish = () => localStorage.getItem("i18nextLng") === "es";
const isFrench = () => localStorage.getItem("i18nextLng") === "fr";

const StepExplanation = ({
  header,
  explanation,
  position,
  className,
  image,
  imageStyle,
  id,
  highlight,
  bgColor,
  completed,
}) => {
  const { t } = useTranslation();
  const excludedLines = [
    "complete your proﬁle & cv",
    "keep your skills current",
    "job offer",
  ];
  return (
    <div>
      {id === "interview" ? (
        <ArcherElement
          id="interview-image"
          relations={[
            {
              targetId: "keep-skills-image",
              targetAnchor: "bottom",
              sourceAnchor: "top",
              style: { strokeDasharray: "11", endMarker: false },
            },
          ]}
        >
          <img
            src={image}
            alt={`${id}`}
            className={`w-[10rem] md:absolute ${imageStyle}`}
            style={{ zIndex: 2 }}
          />
        </ArcherElement>
      ) : id === "skill-maintenance" ? (
        <ArcherElement
          id="keep-skills-image"
          relations={[
            {
              targetId: "improve-english-image",
              targetAnchor: "bottom",
              sourceAnchor: "bottom",
              style: { strokeDasharray: "11", endMarker: false },
            },
          ]}
        >
          <img
            src={image}
            alt={`${id}`}
            className={`w-[10rem] md:absolute ${imageStyle}`}
            style={{ zIndex: 2 }}
          />
        </ArcherElement>
      ) : id === "improve-english" ? (
        <ArcherElement
          id="improve-english-image"
          relations={[
            {
              targetId: "explore-jobs",
              targetAnchor: "left",
              sourceAnchor: "bottom",
              style: { strokeDasharray: "11" },
            },
          ]}
        >
          <img
            src={image}
            alt={`${id}`}
            className={`w-[10rem] md:absolute ${imageStyle}`}
            style={{ zIndex: 2 }}
          />
        </ArcherElement>
      ) : (
        <img
          src={image}
          alt={`${id}`}
          className={`w-[10rem] md:absolute ${imageStyle}`}
          style={{ zIndex: 2 }}
        />
      )}
      <ArcherElement
        id="interview"
        relations={[
          {
            targetId: "shortlist",
            targetAnchor: "left",
            sourceAnchor: "top",
            style: { strokeDasharray: "11" },
          },
        ]}
      >
        <p className="absolute w-[70%] md:w-[40%] lg:w-[35%] xl:w-[30%] 2xl:w-[25%] top-[65%] md:ml-[13%] lg:ml-[19%] xl:ml-[22%] 2xl:ml-[28%]"></p>
      </ArcherElement>
      <ArcherElement
        id="interview-heading"
        relations={[
          {
            targetId: "interview-image",
            targetAnchor: "right",
            sourceAnchor: "left",
            style: { strokeDasharray: "11", endMarker: false },
          },
        ]}
      >
        <p className="absolute w-[70%] md:w-[40%] lg:w-[35%] xl:w-[30%] 2xl:w-[25%] top-[65%] md:ml-[33%] lg:ml-[36%] xl:ml-[37%] 2xl:ml-[40%]"></p>
      </ArcherElement>

      <div
        className={`md:absolute text-left w-[70%] md:w-[40%] lg:w-[35%] xl:w-[30%] 2xl:w-[25%] m-auto ${
          position === "right"
            ? "md:ml-[58%] lg:ml-[56%] xl:ml-[55%] 2xl:ml-[54%]"
            : "md:ml-[6%] lg:ml-[12%] xl:ml-[17%] 2xl:ml-[22.5%]"
        } ${className}`}
      >
        <div className="relative">
          {completed && !highlight && rtlLang && (
            <i className="fa-solid md:!hidden fa-check text-[1.5rem] text-lightRed mt-[1.25rem] ml-[5rem]"></i>
          )}
          <h3
            id={id}
            className={`inline-block md:block ${
              rtlLang && "float-right md:float-none"
            } ${
              position === "left" && "md:text-right md:pr-8"
            } text-darkBlue italic`}
            style={changeLanguageDirection()}
          >
            {header}
          </h3>
          {completed && !highlight && !rtlLang && (
            <i className="fa-solid md:!hidden fa-check text-[1.5rem] text-lightRed ml-4"></i>
          )}
          {highlight && (
            <PulsatingCircle
              className="md:hidden"
              bgColor={bgColor}
              rtlLang={rtlLang}
            />
          )}
        </div>
        <p
          className={`text-base ${
            rtlLang && "clear-both text-right !pr-0 md:pr-8 md:text-left"
          } ${position === "left" && "md:text-right pr-8"}  ${
            isSpanish() &&
            header === `${t("Keep your skills current")}` &&
            "md:w-[100%]"
          }  ${
            isFrench() &&
            header === `${t("Keep your skills current")}` &&
            "md:w-[106%] lg:w-auto"
          } `}
          style={changeLanguageDirection()}
        >
          {explanation}
        </p>

        {!excludedLines.includes(header.toLowerCase()) && <LineDivider />}
      </div>
    </div>
  );
};

export default StepExplanation;
