import React from "react";
import "./PulsatingCircle.css";

const PulsatingCircle = ({ top, className, style, bgColor, rtlLang }) => {
  const circleStyle = {
    "--circle-bg-color": bgColor || "#E9E5DE",
  };

  return (
    <span
      className={`pulsating-circle left-[80%] ${
        rtlLang && "left-[5rem]"
      } md:left-[31%] top-[20px] md:top-[1px] md:top-[${top}] ${className}`}
      style={{ ...style, ...circleStyle }}
    ></span>
  );
};

export default PulsatingCircle;
